import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Row, Col } from 'antd'
import { RightSquareOutlined } from '@ant-design/icons'
import { useStore } from '@/store'

import UserCarousel from '@/components/JSAPE_USER_Carousel'

import SimpleList from '@/components/SimpleList'
import './index.scss'
import IsnCarousel from '@/components/Isn_Carousel'
import ImageLink from '@/components/JSAPE_Img_Link'

import SearchBox from '@/components/SearchBox'

function Home () {
  const { articlesStore, crouselStore } = useStore()
  // const navigate = useNavigate()
  // const toDetail = (id) => {
  //   navigate(`?id=${id}`)
  // }
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col lg={7} md={24} sm={24} order={1}>
          <Card type="inner" title="行业新闻" bordered={true} extra={<a href="/articles/hyxw"><RightSquareOutlined /></a>} >
            <SimpleList store={articlesStore} channel={'hyxw'} limit={6} />
          </Card>
        </Col>
        <Col lg={10} md={24} sm={24} order={2}>
          <IsnCarousel store={crouselStore} />
        </Col>
        <Col lg={7} md={24} sm={24} order={3}>
          <SearchBox />
          <div style={{ marginTop: '16px', paddingLeft: '8px' }}>
            <UserCarousel style={{ marginTop: '8px' }} />
          </div>
        </Col>
        {/* </Row>
      <div style={{ marginTop: '12px' }}></div>
      <Row gutter={8}> */}
        <Col lg={7} md={24} sm={24} order={4}>
          <Card type="inner" title="协会新闻" bordered={true} extra={<a href="/articles/xhxw"><RightSquareOutlined /></a>} >
            <SimpleList store={articlesStore} channel={'xhxw'} limit={8} />
          </Card>
        </Col>
        <Col lg={10} md={24} sm={24} order={5}>
          <Card type="inner" title="技术交流" bordered={true} extra={<a href="/articles/jsjl"><RightSquareOutlined /></a>} >
            <SimpleList store={articlesStore} channel={'jsjl'} limit={8} />
          </Card>
        </Col>
        <Col lg={7} md={24} sm={24} order={6}>
          <Card type="inner" title="工程信息" bordered={true} extra={<a href="/articles/gcxx"><RightSquareOutlined /></a>}>
            <SimpleList store={articlesStore} channel={'gcxx'} limit={8} />
          </Card>
        </Col>
      </Row>
      <ImageLink limit={32} />

    </>
  )
}

export default observer(Home)