import axios from 'axios'

const _baseURL = 'https://api.jsape.com'
//const _baseURL = 'https://is-cloud-0gdcio74bbc60c7c-1256674409.ap-shanghai.service.tcloudbase.com/api/v1.0/'
const _token = 'ncs79e2klbAUxh_QP9SJkbEkZbvpkNpKVRe4S2GYjLdV0CXTZEBHdTJXKN_PohjHdFoqsM3co-VLw-4iV6K04_CYX9wLDwZ-Z2yPEWhpHpuNNouieN_vCIoNL9-AoatS'


const http = axios.create({
  baseURL: _baseURL,
  timeout: 5000
})
// 添加请求拦截器
http.interceptors.request.use((config) => {

  const token = _token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response.data
}, (error) => {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

export { http }