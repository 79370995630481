import { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { Divider, Typography } from 'antd'

import JSAPEBreadcrumb from '@/components/JSAPE_Breadcrumb'

import { useParams } from 'react-router-dom'

import "./index.scss"

const { Title } = Typography

function Articles_Detail (props) {

  const params = useParams()
  var channel = props.channel
  if (!channel) channel = params.channel

  const id = params.id
  //console.log('channel', channel)

  const { articlesStore } = useStore()

  const [data, setData] = useState({})

  useEffect(() => {
    const get = async () => {
      await articlesStore.getById({ channel, id })
      setData(
        articlesStore.result.data[0]
      )
    }
    get()

  }, [channel, id])


  return (
    <>
      <JSAPEBreadcrumb channel={channel} />

      <Divider />

      <Title>{data.title}</Title>
      {data.subheading ? <Title level={2}>{data.subheading}</Title> : null}
      <div dangerouslySetInnerHTML={{ __html: data.content }}>
      </div>
    </>

  )
}

export default Articles_Detail