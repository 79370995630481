
import { useEffect, useState } from 'react'

import { Card, Row, Col } from 'antd'

import { http } from '@/utils'

const { Meta } = Card

function ImageLink ({ limit = 20, skip = 0, sort }) {

  const [data, setData] = useState({
    list: [],

  })

  useEffect(() => {
    const get = async () => {
      const query = { "$and": [{ 'enable': { '$eq': true } }] }
      var uri = `https://api.jsape.com/api/v1.0/pic-links/find?limit=${limit}&skip=${skip}}`
      //console.log(uri)
      const res = await http.post(uri, {
        query: {
          ...query
        },
        sort: { 'sort_number': -1 }
      })
      //console.log('res-------:', res)
      setData({
        list: res.data,
      }
      )
    }
    get()

  }, [limit, skip, sort])

  // const navigate = useNavigate()

  return (
    <>
      <Row gutter={16}>
        {

          data.list.map(
            item => <Col lg={3} md={12} sd={12} key={item._id} style={{ marginTop: '2em' }}>
              <Card onClick={() => window.open(item.url)}
                hoverable
                cover={<img alt='' src={item.img} style={{ height: 98, width: '100%' }} />}>
                <Meta title={item.title} description={item.description} />
              </Card>
            </Col>
          )
        }
      </Row>

    </>
  )

}



export default ImageLink