import { useStore } from '@/store'
import { Carousel, Typography } from 'antd'
import { useEffect, useState } from 'react'
const { Link } = Typography

function UserCarousel () {
  const [data, setData] = useState({
    list: [],

  })
  const { memberStore } = useStore()
  useEffect(() => {

    const getList = async () => {

      const res = await memberStore.list({})
      setData({
        list: res.data,
      })
    }

    getList()

  }, [memberStore])



  return (
    <Carousel autoplay dotPosition='right'>
      <div>
        <div style={{ height: 260 }}>
          {
            data.list.slice(0, 7).map(
              item => <p key={item._id} className='ellipsis'>
                <Link href={
                  '/members/' + item._id
                }>{item.CompanyName}</Link></p>

            )
          }
        </div>
      </div>
      <div>
        <div style={{ height: 260 }}>
          {
            data.list.slice(8, 15).map(
              item => <p key={item._id} className='ellipsis'>
                <Link href={
                  '/members/' + item._id
                }>{item.CompanyName}</Link></p>
            )
          }
        </div></div>
      <div>
        <div style={{ height: 260 }}>
          {
            data.list.slice(16, 23).map(
              item => <p key={item._id} className='ellipsis'>
                <Link href={
                  '/members/' + item._id
                }>{item.CompanyName}</Link></p>
            )
          }
        </div></div>
      <div>
        <div style={{ height: 260 }}>
          {
            data.list.slice(24, 31).map(
              item => <p key={item._id} className='ellipsis'>
                <Link href={
                  '/members/' + item._id
                }>{item.CompanyName}</Link></p>
            )
          }
        </div></div>
    </Carousel >
  )
}


export default UserCarousel