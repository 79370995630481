//https://docs.cloudbase.net/cms/usage/restful
//https://is-cloud-0gdcio74bbc60c7c-1256674409.ap-shanghai.service.tcloudbase.com/api/v1.0/isn-articles

import { makeAutoObservable } from "mobx"
import { http } from '@/utils'

class CrouselStore {

  data = {}

  constructor() {
    makeAutoObservable(this)
  }

  list = async ({ limit, skip, sort }) => {
    const params = { limit: 20, skip: 0, }

    if (limit) params.limit = limit
    if (skip) params.skip = skip
    if (sort) params.sort = sort

    const query = { "$and": [{ 'enable': { '$eq': true } }] }
    //https://is-cloud-0gdcio74bbc60c7c-1256674409.ap-shanghai.service.tcloudbase.com/api/v1.0/carousel
    //https://api.jsape.com/api/v1.0/carousel
    //POST /{collectionName}/find?limit={limit}&skip={skip}&fields={fields}&sort={sort}
    var uri = `https://api.jsape.com/api/v1.0/carousel/find?limit=${params.limit}&skip=${params.skip}}`
    const res = await http.post(uri, {
      query: {
        ...query
      },
      sort: { 'sort_number': -1 }
    })

    this.data = res
    //console.log(query)
    return res
  }//list

}

export default CrouselStore