import React from 'react'
import { observer } from 'mobx-react-lite'
import { message, Layout, Divider, Breadcrumb, Form, Input, Button } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import { useStore } from '@/store'

import MemberCenterSider from '@/components/MemberCenterSider'

import './index.scss'

const { Sider, Content } = Layout


function NewPasword () {

  const { loginStore } = useStore()

  const userid = loginStore.userid

  const on_update_password = async (values) => {
    //console.log('on_update_password', userid, values)
    const res = await loginStore.newPassword({ userid, ...values })
    if (res.result.errno !== 0) {
      message.error(res.result.message)
    }
    else {
      message.success('更新密码操作完成')
    }
  }

  return (
    <>
      <Layout>
        <Sider theme="light" width={300}>
          <MemberCenterSider />
        </Sider>
        <Content>
          <div className="site-layout-background">
            <Breadcrumb separator="">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Separator>:</Breadcrumb.Separator>
              <Breadcrumb.Item href="/">
                首页
              </Breadcrumb.Item>
              <Breadcrumb.Separator />

              <Breadcrumb.Item>我的</Breadcrumb.Item>
              <Breadcrumb.Separator />
              <Breadcrumb.Item>更新密码</Breadcrumb.Item>

            </Breadcrumb>

            <Divider />
            <Form name="login_form" layout='vertical' size="large" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={on_update_password}>
              <Form.Item label="旧密码" name="old_password" rules={[{
                required: true,
                message: '请输入旧登陆密码'
              }]}>
                <Input prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password" />
              </Form.Item>
              <Form.Item label="新密码" name="new_password" rules={[
                {
                  required: true,
                  message: '请输入新登陆密码',
                },
              ]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  更新密码
                </Button>

              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>


    </>
  )
}

export default observer(NewPasword)