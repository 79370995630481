import { Select, Input, Row, Col } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const { Search } = Input
const { Option } = Select



function SearchBox () {

  const DefaultSearchTarget = 'members'

  const [target, setTarget] = useState(DefaultSearchTarget)

  const navigate = useNavigate()

  const handleChange = (value) => {
    setTarget(value)
  }
  const onSearch = (value) => {
    if (target == 'members') {
      navigate(`/members/search?q=${value}`)
    }
    else {
      navigate(`/articles/search?channel=${target}&q=${value}`)
    }
  }

  return (
    <>
      <Row>
        <Col span={8}>
          <Select style={{ width: '100%' }} defaultValue={DefaultSearchTarget} onChange={handleChange}>
            <Option value="members">会员单位</Option>
            <Option value="hyxw">行业新闻</Option>
            <Option value="xhxw">协会新闻</Option>
            <Option value="jsjl">技术交流</Option>
            <Option value="gcxx">工程信息</Option>

          </Select>
        </Col>
        <Col span={16}>
          <Search

            placeholder="输入搜索关键字"
            onSearch={onSearch} enterButton />
        </Col></Row>
    </>
  )
}

export default SearchBox