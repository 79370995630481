import { useStore } from '@/store'
import { Card } from 'antd'
import SearchBox from '@/components/SearchBox'
import SimpleList from '@/components/SimpleList'


function JSAPESiderArticles () {

  const { articlesStore } = useStore()

  return (
    <>
      <SearchBox />
      <div style={{ marginTop: '12px' }}></div>
      <Card type="inner" title='协会新闻' extra={<a href="/articles/xhxw">More</a>} >
        <SimpleList store={articlesStore} channel={'xhxw'} limit={10} />
      </Card>
      <Card type="inner" title="行业新闻" extra={<a href="/articles/hyxw">More</a>} >
        <SimpleList store={articlesStore} channel={'hyxw'} limit={10} />
      </Card>
      <Card type="inner" title="技术交流" extra={<a href="/articles/jsjl">More</a>} >
        <SimpleList store={articlesStore} channel={'jsjl'} limit={10} />
      </Card>
    </>
  )
}

export default JSAPESiderArticles