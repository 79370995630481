import { useStore } from '@/store'
import { List, Typography } from 'antd'
import { useEffect, useState } from 'react'

const { Text, Link } = Typography

const SimpleList = (props) => {
  const [result, setResult] = useState({})
  const { articlesStore } = useStore()
  console.log(props.channel)

  useEffect(() => {
    console.log('propspropsprops', props)
    const getList = async () => {
      console.log('props', props)
      const res = await articlesStore.list(props)
      setResult(res)
    }

    getList()

  }, [props, articlesStore])
  return (

    < List size="small" dataSource={result.data} renderItem={(item) => (
      <List.Item>
        {
          item.url ? <Link href={item.url} target='_blank'>{item.title}</Link>
            : <Link href={'/articles/' + item.channel + '/' + item._id}>{item.title}</Link>
        }
      </List.Item>
    )} />

  )
}


export default SimpleList
