
import { useEffect, useState } from 'react'
import { Carousel, Typography } from 'antd'

const { Link } = Typography


function IsnCarousel ({ store, limit, skip, sort }) {

  const [data, setData] = useState({
    list: [],

  })

  useEffect(() => {
    const get = async () => {
      const res = await store.list({ limit, skip, sort })
      console.log('res:', res)
      setData({
        list: res.data,
      }
      )
    }
    get()

  }, [limit, skip, sort])


  return (
    <>
      <Carousel autoplay>
        {

          data.list.map(
            item => <div key={item._id}>
              <Link href={item.url} target='_blank'>
                <img alt='' src={item.img} class='carousel' />
              </Link>
            </div>
          )
        }
      </Carousel>

    </>
  )

}

// Isn_Carousel.propTypes = {
//   store: PropTypes.object.isRequired,

// }

export default IsnCarousel