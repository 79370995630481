import { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from '@/store'
import { observer } from 'mobx-react-lite'

function OnePage ({ store2, collection, make }) {

  const { articlesStore } = useStore()

  const [data, setData] = useState({})

  useEffect(() => {
    const get = async () => {
      await articlesStore.getOnePage({ collection, make })

      setData(
        articlesStore.result.data[0]
      )
    }
    get()

  }, [collection, make])


  return (
    <>

      <div dangerouslySetInnerHTML={{ __html: data.content }}>
      </div>
    </>
  )

}

OnePage.propTypes = {
  //store: PropTypes.object.isRequired,
  collection: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired
}

export default observer(OnePage)