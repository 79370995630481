
import { useRef } from 'react'
import { useEffect } from 'react'
import { Select } from 'antd'
const { Option } = Select

const constraints = {
  'qvga': { 'width': 320, 'height': 240 },
  'vga': { 'width': 640, 'height': 480 },
  'hd': { 'width': 1280, 'height': 720 },
  'fullhd': { 'width': 1920, 'height': 1080 },
  'twoK': { 'width': 2560, 'height': 1440 },
  'fourK': { 'width': 4096, 'height': 2160 },
  'eightK': { 'width': 7680, 'height': 4320 },
}
//QVGA 320*240
const qvgaConstraints = {
  video: { width: { exact: 320 }, height: { exact: 240 } }
}

function Test () {

  const video = useRef(null)
  const canvas = useRef(null)
  var stream

  useEffect(() => {
    handleChange('qvga')
  }, [])

  const openVideo = async (constraints) => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop()
      })
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints)

      // const videoTracks = stream.getVideoTracks()
      // console.log(`设备是 ${videoTracks[0].label}`)
      window.stream = stream
      video.current.srcObject = stream
    } catch (e) {
      console.log(e.name)
    }

  }

  const screenSharing = () => {
    navigator.mediaDevices.getDisplayMedia({ video: true })
      .then((stream) => {
        video.current.srcObject = stream
      }).catch((err) => {
        console.log(err)
      })
  }

  const takesnap = async () => {
    canvas.current.height = video.current.videoHeight
    canvas.current.width = video.current.videoWidth
    canvas.current.getContext('2d').drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height)
  }

  const handleChange = (v) => {

    var width = constraints[v].width
    var height = constraints[v].height
    var constraint = { video: { "width": { "exact": width }, "height": { "exact": height } } }
    console.log(constraint)
    openVideo(constraint)
  }
  return (
    <>
      <video ref={video} style={{ width: 640, height: 480 }} autoPlay controls />
      <Select onChange={handleChange} defaultValue='qvga'>
        <Option value="qvga">QVGA</Option>
        <Option value="vga">VGA</Option>
        <Option value="hd">高清</Option>
        <Option value="fullhd">超清</Option>
        <Option value="twoK">2K</Option>
        <Option value="fourK">4K</Option>
        <Option value="eightK">8K</Option>
      </Select>
      {/* <button onClick={screenSharing}>共享屏幕</button> */}
      {/* <button onClick={takesnap}>截屏</button>
      <canvas ref={canvas} style={{ width: 320, height: 240 }} /> */}
    </>
  )
}

export default Test