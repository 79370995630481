import React from 'react'
import { Layout, Breadcrumb, Divider } from 'antd'

import OnePage from '@/components/OnePage'

import { useParams } from 'react-router-dom'
import JSAPEAboutSide from '@/components/JSAPE_About_Side'

const { Sider, Content } = Layout

const pages = {
  'about-p1': '协会简介',
  'about-p2': '领导致词',
  'about-p3': '理事长、副理事长、秘书长',
  'about-p4': '常务理事、监事、理事',
  'about-p5': '协会秘书处',
  'about-p6': '协会章程',
  'about-p7': '协会职能',
  'about-p8': '协会内设组织机构',
  'about-p9': '入会指南',
}



function breadcrumb (page) {
  return (
    <Breadcrumb separator="">
      <Breadcrumb.Item>当前位置</Breadcrumb.Item>
      <Breadcrumb.Separator>:</Breadcrumb.Separator>
      <Breadcrumb.Item href="/">
        首页
      </Breadcrumb.Item>
      <Breadcrumb.Separator />
      <Breadcrumb.Item>
        关于我们
      </Breadcrumb.Item>
      <Breadcrumb.Separator />
      <Breadcrumb.Item>{pages[page]}</Breadcrumb.Item>
    </Breadcrumb>
  )
}

function Aboutus (props) {
  const params = useParams()
  var page = props.page
  if (!page) page = params.page

  return (
    <>
      <Layout>
        <Sider theme="light" width={300}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}>
          <JSAPEAboutSide selectKey={[page]} />
        </Sider>
        <Content>
          <div className="site-layout-background">
            {breadcrumb(page)}
            <Divider />
            <OnePage collection="aboutus" make={page} />
          </div>
        </Content>
      </Layout>
    </>

  )
}

export default Aboutus