import { useNavigate } from 'react-router-dom'

import { useStore } from '@/store'

import { HighlightOutlined, ContactsOutlined, PoweroffOutlined, ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons'

import { Menu, Modal } from 'antd'

const { confirm } = Modal

const menu_item = [
  {
    icon: <UserAddOutlined />,
    label: '注册信息',
    key: 'info',
  },
  {
    icon: <ContactsOutlined />,
    label: '企业简介',
    key: 'profile',
  },
  {
    icon: <HighlightOutlined />,
    label: '更新密码',
    key: 'new-password',
  },
  {
    icon: <PoweroffOutlined />,
    label: '退出登陆',
    key: 'logout',
  },

]



function MemberCenterSider ({ target }) {
  const { loginStore } = useStore()

  const navigate = useNavigate()

  var onLogout = () => {
    confirm({
      title: '确认要退出嘛?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk () {
        loginStore.logout()
        navigate('/')
      },
      onCancel () {
        return
      },
    })
  }



  const onClick = ({ key }) => {
    console.log(key)
    switch (key) {
      case 'logout':
        onLogout()
        break
      case 'info':
      case 'profile':
      case 'new-password':
        navigate(`/members/my/${key}`)
        break

    }
  }
  return (
    <>
      <Menu mode="vertical" onClick={onClick} items={menu_item} defaultSelectedKeys={target} />
    </>
  )
}

export default MemberCenterSider