import { Navigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'


function AuthComponent ({ children }) {
  const { loginStore } = useStore()
  const { pathname } = useLocation()
  //console.log(pathname)
  return (

    loginStore.isLogin ?
      <> {children} </>
      :
      <Navigate to={`/login?ref=${pathname}`} r replace />

  )

}


export default observer(AuthComponent)