import React from 'react'
import { observer } from 'mobx-react-lite'
import { Layout, Descriptions, Divider, Breadcrumb } from 'antd'


import { useStore } from '@/store'
import { useParams } from 'react-router-dom'

import MemberCenterSider from '@/components/MemberCenterSider'

import './index.scss'
import { useEffect } from 'react'
import { useState } from 'react'

const { Sider, Content } = Layout



const pages = {
  'info': '注册信息',
  'profile': '企业简介',
  'new-password': '更新密码',
}

function MemberCenter () {

  const [user, setUser] = useState({})

  const { loginStore, memberStore } = useStore()

  const params = useParams()
  const target = params.get || 'info'

  const userid = loginStore.userid

  useEffect(() => {
    const getData = async () => {
      await memberStore.getById(userid)
      setUser(memberStore.data)
      //setUser(res.data)
    }

    //console.log('loginStore.userid', loginStore.userid)
    getData()


    console.log('---data---', user)
  }, [userid])

  return (
    <>
      <Layout>
        <Sider theme="light" width={300}>
          <MemberCenterSider target={target} />
        </Sider>
        <Content>
          <div className="site-layout-background">
            <Breadcrumb separator="">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Separator>:</Breadcrumb.Separator>
              <Breadcrumb.Item href="/">
                首页
              </Breadcrumb.Item>
              <Breadcrumb.Separator />

              <Breadcrumb.Item>我的</Breadcrumb.Item>
              <Breadcrumb.Separator />
              <Breadcrumb.Item>{pages[target]}</Breadcrumb.Item>

            </Breadcrumb>

            <Divider />
            <h3>
              {user.CompanyName}
            </h3>
            {(target === 'info') ?
              <Descriptions bordered>
                <Descriptions.Item label="联系人">{user.Name} {user.title}</Descriptions.Item>
                <Descriptions.Item label="电话">{user.CityNo} - {user.Phone} {user.MobileNo}</Descriptions.Item>
                <Descriptions.Item label="传真">{user.Fax}</Descriptions.Item>
                <Descriptions.Item label="Email">{user.Email}</Descriptions.Item>
                <Descriptions.Item label="网址">{user.SiteURL}</Descriptions.Item>
                <Descriptions.Item label="邮编">{user.PostalCode}</Descriptions.Item>
                <Descriptions.Item label="地址：">
                  {user.Address}
                </Descriptions.Item>
              </Descriptions>
              : <div dangerouslySetInnerHTML={{ __html: user.Content }}>
              </div>
            }
          </div>
        </Content>
      </Layout>


    </>
  )
}

export default observer(MemberCenter)