//https://docs.cloudbase.net/cms/usage/restful
//https://is-cloud-0gdcio74bbc60c7c-1256674409.ap-shanghai.service.tcloudbase.com/api/v1.0/isn-articles

import { makeAutoObservable } from "mobx"
import { http } from '@/utils'

const COLLECTION_NAME = 'users'

class MemberStore {

  data = {}

  constructor() {
    makeAutoObservable(this)
  }

  list = async ({ limit = 35, skip = 0 }) => {
    const query = { "$and": [{ 'enable': { '$eq': true } }] }
    //https://is-cloud-0gdcio74bbc60c7c-1256674409.ap-shanghai.service.tcloudbase.com/api/v1.0/carousel
    //https://api.jsape.com/api/v1.0/carousel
    //POST /{collectionName}/find?limit={limit}&skip={skip}&fields={fields}&sort={sort}
    var uri = `https://api.jsape.com/api/v1.0/users/find?limit=${limit}&skip=${skip}&sort={"_createTime":-1}`
    const res = await http.post(uri, {
      sort: { '_createTime': -1, '_id': -1 }
    })

    this.data = res
    //console.log('user:', res)
    return res
  }//list

  search = async ({ limit = 20, skip = 0, query, sort }) => {
    const uri = '/users/search'

    return await http.get(uri, {
      params: {
        'limit': limit, 'offset': skip, 'keywords': query
      }
    }).then((res) => {
      //this.data = res
      return res
    }).catch((err) => {
      console.err(err)
    })
  }


  //GET /{collectionName}/{docId}
  getById = async (docId) => {
    const res = await http.get(`https://api.jsape.com/api/v1.0/${COLLECTION_NAME}/${docId}`)
    this.data = res.data

    //console.log(`${COLLECTION_NAME}/${docId}`, res)
    return res
  }//get



}

export default MemberStore