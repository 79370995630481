import { makeAutoObservable } from "mobx"
import { setToken, getToken, clearToken } from '@/utils'

import cloudbase from "@cloudbase/js-sdk"

const LOGIN_STORE_USER_KEY = '_ISPUB_LOGIN_STORE_USER_'

const app = cloudbase.init({
  env: "is-cloud-0gdcio74bbc60c7c"
})

const auth = app.auth({ persistence: "local" })

class LoginStore {

  token = getToken() || ''

  nickName = ''
  userid = ''
  isLogin = false

  constructor() {
    makeAutoObservable(this)
    this.loginState()
  }


  loginState = () => {
    //用户显式退出或更改密码之前的30天一直有效 })
    //const auth = app.auth({ persistence: "local" })
    const loginState = auth.hasLoginState()
    //console.log('loginState', loginState)
    const user = JSON.parse(localStorage.getItem(LOGIN_STORE_USER_KEY))
    if (user) {
      this.userid = user.userid || ''
      this.nickName = user.nickName || ''
    }

    // 1. 建议登录前检查当前是否已经登录
    if (loginState) {
      //console.log('已登录')
      this.isLogin = true

    } else {
      //console.log('未登录')
      this.isLogin = false
    }
    return this.isLogin
  }

  newPassword = async ({ userid, old_password, new_password }) => {
    //console.log(userid, old_password, new_password)
    const res = await app.callFunction({
      name: 'Jsape-newPassword',
      data: {
        userid, old_password, new_password
      }
    })

    return res
  }

  login = async ({ loginname, password }) => {
    //console.log(loginname, password)
    const res = await app.callFunction({
      name: 'Jsape-authorizations',
      data: {
        "loginname": loginname,
        "password": password
      }
    })
    //.then(async (res) => {

    //console.log('token', res.result.token)
    if (res.result.token !== undefined) {
      this.token = res.result.token
      this.userid = res.result.userid
      this.nickName = res.result.nickName

      localStorage.setItem(LOGIN_STORE_USER_KEY, JSON.stringify({
        'userid': this.userid, 'nickName': this.nickName
      }))

      this.isLogin = true
      setToken(this.token)
      auth.customAuthProvider().signIn(this.token)
      return true
    } else {
      this.isLogin = false
      return false
    }
    // }).catch(console.error)
  }

  logout = () => {
    //const auth = app.auth()
    clearToken()
    this.isLogin = false
    auth.signOut()
  }

}

export default LoginStore