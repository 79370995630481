import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useStore } from '@/store'

import AuthComponent from '@/components/AuthComponent'

import Home from '@/pages/Home'
import Login from "@/pages/Member/login"
import About from '@/pages/Aboutus'
import MemberSearch from "@/pages/Member/search"
import MemberDetail from "./pages/Member/detail"
import Articles from "@/pages/Articles"
import ArticlesDetail from "@/pages/Articles/detail"
import ArticlesSearch from '@/pages/Articles/search'
import MemberCenter from '@/pages/Member/center'

import Test from '@/pages/Test'
import LayoutSider from "./components/Layout_Sider"
import LayoutNotSider from "./components/Layout_NotSider"
import NewPasword from './pages/Member/newPassword'
import NotFound from './pages/NotFound'

function App () {
  const { loginStore } = useStore()
  loginStore.loginState()

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<LayoutNotSider />}>
            <Route index element={<Home />}></Route>
            <Route path="login" element={<Login />}></Route>

          </Route>
          <Route path="/aboutus/" element={<LayoutNotSider />}>
            <Route index element={<About page='about-p1' />}></Route>
            <Route path=":page" element={<About />}></Route>
          </Route>

          <Route path="/members/my/" element={<LayoutNotSider />}>
            <Route index element={<AuthComponent><MemberCenter /></AuthComponent>}></Route>
            <Route path="new-password" element={<AuthComponent><NewPasword /></AuthComponent>}></Route>
            <Route path=":get" element={<AuthComponent><MemberCenter /></AuthComponent>}></Route>
          </Route>

          <Route path="/members/" element={<LayoutSider />}>
            <Route path="search" element={<MemberSearch />}></Route>
            <Route path=":id" element={<MemberDetail />}></Route>
          </Route>
          <Route path="/articles/" element={<LayoutSider />}>
            <Route path="search" element={<ArticlesSearch />}></Route>
            <Route path=":channel" element={<Articles />}></Route>
            <Route path="jsjl/:id" element={<ArticlesDetail channel='jsjl' />}></Route>
            <Route path="gcxx/:id" element={<AuthComponent><ArticlesDetail channel='gcxx' /></AuthComponent>}></Route>
            <Route path="zjk/:id" element={<ArticlesDetail channel='zjk' />}></Route>
            <Route path=":channel/:id" element={<ArticlesDetail />}></Route>
          </Route>

          <Route path="/test" element={<Test />}></Route>
          <Route path="notfound" element={<NotFound />}></Route>
          <Route path="*" element={<NotFound />}></Route>

        </Routes>
      </div>
    </BrowserRouter >
  )
}

export default (App)
