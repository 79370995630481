import { Layout, Menu, Row, Col, Affix } from 'antd'
import { WhatsAppOutlined, HomeOutlined, TeamOutlined, ReadOutlined, VideoCameraOutlined, UsergroupAddOutlined, CommentOutlined, ApiOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import LoginState from '@/components/LoginState'

import topimg from '@/assets/images/top.png'
import { useState } from 'react'

const items = [
  {
    label: '站点首页',
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: '关于我们',
    key: 'about-p1',//'aboutus',
    icon: <TeamOutlined />,
  },
  {
    label: '行业新闻',
    key: 'hyxw',
    icon: <ReadOutlined />,
  },
  {
    label: '协会新闻',
    key: 'xhxw',
    icon: <VideoCameraOutlined />,
  },

  {
    label: '技术交流',
    key: 'jsjl',
    icon: <CommentOutlined />,
  },
  {
    label: '工程信息',
    key: 'gcxx',
    icon: <ApiOutlined />,
  },
  {
    label: '专家库',
    key: 'zjk',
    icon: <UsergroupAddOutlined />,
  },
  {
    label: '联系我们',
    key: 'about-p10',
    icon: <WhatsAppOutlined />,
  }
]

const { Header } = Layout


function JSAPEHeader ({ selectKey }) {
  const [top] = useState(0)
  //var [isLogin, setIsLogin] = useState(false)

  const navigate = useNavigate()
  // const toDetail = (id) => {
  //   navigate(`/test?id=${id}`)
  // }
  const onClick = ({ key }) => {

    switch (key) {
      case 'home':
        navigate('/')
        break
      case 'about-p1':
      case 'about-p2':
      case 'about-p3':
      case 'about-p4':
      case 'about-p5':
      case 'about-p6':
      case 'about-p7':
      case 'about-p10':
        navigate(`/aboutus/${key}`)
        break
      case 'xhxw':
      case 'hyxw':
      case 'jsjl':
      case 'gcxx':
      case 'zjk':
        navigate(`/articles/${key}`)
        break
      default:
        navigate(`/404`)
    }
  }

  // const { loginStore } = useStore()
  // var onLogin = async (values) => {
  //   console.log(values)
  //   await loginStore.login({ loginname: values.loginname, password: values.password })
  //   console.log(loginStore.token)
  // }

  // var onLogout = () => {
  //   loginStore.logout()
  // }

  // useEffect(() => {
  //   const _isLogin = loginStore.loginState()
  //   //setIsLogin(_isLogin)
  // }, [])



  return (
    <>



      <img alt='' className="responsive-image" src={topimg} />
      <Affix offsetTop={top}>
        <Header theme='light'>
          <Row align="middle">
            <Col xl={22} md={20}><Menu mode="horizontal" onClick={onClick} items={items} defaultSelectedKeys={selectKey} /></Col>
            <Col xl={2} md={4}><LoginState /></Col>
          </Row>
        </Header>
      </Affix>
    </>
  )
}

export default (JSAPEHeader)