import { useParams, Outlet } from 'react-router-dom'
import JSAPEHeader from '@/components/JSAPE_Header'
import JSAPEFooter from '@/components/JSAPE_Footer'
import { Layout } from 'antd'
import JSAPESiderArticles from './JSAPE_Sider_Articles'
const { Sider, Content } = Layout

function LayoutSider () {
  const params = useParams()
  const channel = params.channel

  return (
    <>
      <Layout>
        <JSAPEHeader selectKey={[channel]} />
        <Layout>
          <Sider theme="light" width={300}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken)
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type)
            }}
          >
            <JSAPESiderArticles />
          </Sider>
          <Content>
            <div className="site-layout-background">
              <Outlet />
            </div>
          </Content>
        </Layout>
        <JSAPEFooter />
      </Layout>
    </>
  )
}

export default LayoutSider