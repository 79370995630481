import { useEffect, useState } from 'react'
import { useStore } from '@/store'

import { Breadcrumb, Divider, Typography } from 'antd'


import { useParams } from 'react-router-dom'

import { Descriptions } from 'antd'

import "./index.scss"


const { Title } = Typography

function MemberDetail () {

  const params = useParams()
  const id = params.id


  const { memberStore } = useStore()

  const [data, setData] = useState({})

  useEffect(() => {
    const get = async () => {
      const res = await memberStore.getById(id)
      //console.log(res)
      setData(
        res.data
      )
    }
    get()

  }, [id, memberStore])


  return (
    <>
      <Breadcrumb separator="">
        <Breadcrumb.Item>当前位置</Breadcrumb.Item>
        <Breadcrumb.Separator>:</Breadcrumb.Separator>
        <Breadcrumb.Item href="/">
          首页
        </Breadcrumb.Item>
        <Breadcrumb.Separator />

        <Breadcrumb.Item>会员信息</Breadcrumb.Item>


      </Breadcrumb>

      <Divider />

      <Title level={2}>{data.CompanyName}</Title>
      <Descriptions bordered>
        <Descriptions.Item label="联系人">{data.Name} {data.title}</Descriptions.Item>
        <Descriptions.Item label="电话">{data.CityNo} - {data.Phone} {data.MobileNo}</Descriptions.Item>
        <Descriptions.Item label="传真">{data.Fax}</Descriptions.Item>
        <Descriptions.Item label="Email">{data.Email}</Descriptions.Item>
        <Descriptions.Item label="网址">{data.SiteURL}</Descriptions.Item>
        <Descriptions.Item label="邮编">{data.PostalCode}</Descriptions.Item>
        <Descriptions.Item label="地址：">
          {data.Address}
        </Descriptions.Item>

      </Descriptions>

      <div className='content' dangerouslySetInnerHTML={{ __html: data.Content }}>
      </div>
    </>

  )


}

export default MemberDetail