import { Breadcrumb } from 'antd'

const channelList = {
  tz: '通知公告',
  hyxw: '行业新闻',
  xhxw: '协会新闻',
  jsjl: '技术交流',
  gcxx: '工程信息',
  zjk: '专家库'
}

function JSAPEBreadcrumb ({ channel }) {
  return (
    <Breadcrumb separator="">
      <Breadcrumb.Item>当前位置</Breadcrumb.Item>
      <Breadcrumb.Separator>:</Breadcrumb.Separator>
      <Breadcrumb.Item href="/">
        首页
      </Breadcrumb.Item>
      <Breadcrumb.Separator />
      <Breadcrumb.Item href={"/articles/" + channel}>{channelList[channel]}</Breadcrumb.Item>
    </Breadcrumb>
  )
}


export default JSAPEBreadcrumb