import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'

import { LoginOutlined, DownOutlined, ExclamationCircleOutlined, UserAddOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Modal } from 'antd'
const { confirm } = Modal



function LoginState () {
  const { loginStore } = useStore()
  const navigate = useNavigate()


  const onClick = ({ key }) => {
    switch (key) {
      case 'logout':
        onLogout()
        break
      case 'my':
      default:
        navigate('/members/my/')
        break


    }
  }

  const menu1 = (
    <Menu onClick={onClick}
      items={[
        {
          key: 'my',
          icon: <UserAddOutlined />,
          label: '会员中心',
        },
        {
          key: 'logout',
          icon: <PoweroffOutlined />,
          label: '退出登陆',
        },

      ]}
    />)

  const menu2 = (
    <Menu onClick={onClick}
      items={[
        {
          key: 'my',
          icon: <UserAddOutlined />,
          label: '登陆会员中心',
        },

      ]}
    />)

  var onLogout = () => {
    confirm({
      title: '确认要退出嘛?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk () {
        loginStore.logout()
        navigate('/')
      },
      onCancel () {
        return
      },
    })
  }
  return (
    <>
      <Dropdown overlay={loginStore.isLogin ? menu1 : menu2}>
        <span onClick={(e) => e.preventDefault()}>
          <span><LoginOutlined /> 会员中心
            {/* {loginStore.nickName ? loginStore.nickName : '会员中心'} */}
            <DownOutlined />
          </span>
        </span>
      </Dropdown>
    </>
  )
}

export default observer(LoginState)