import { Menu } from 'antd'
import { SolutionOutlined, TeamOutlined, UsergroupAddOutlined, UserAddOutlined, UserSwitchOutlined, NotificationOutlined, WhatsAppOutlined, IdcardOutlined, CompassOutlined, ApartmentOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const menu_item = [
  {
    icon: <NotificationOutlined />,
    label: '协会简介',
    key: 'about-p1',
  },
  {
    icon: <UserAddOutlined />,
    label: '领导致词',
    key: 'about-p2',
  },
  {
    icon: <UserSwitchOutlined />,
    label: '理事长、副理事长、秘书长',
    key: 'about-p3',
  },
  {
    icon: <TeamOutlined />,
    label: '常务理事、监事、理事',
    key: 'about-p4',
  },
  {
    icon: <IdcardOutlined />,
    label: '协会秘书处',
    key: 'about-p5',
  },
  {
    icon: <SolutionOutlined />,
    label: '协会章程',
    key: 'about-p6',
  },
  {
    icon: <CompassOutlined />,
    label: '协会职能',
    key: 'about-p7',
  },
  {
    icon: <ApartmentOutlined />,
    label: '协会内设组织机构',
    key: 'about-p8',
  },
  {
    icon: <CompassOutlined />,
    label: '入会指南',
    key: 'about-p9',
  },
  {
    icon: <UsergroupAddOutlined />,
    label: '协会会员名录',
    key: 'members',
  },
  {
    icon: <WhatsAppOutlined />,
    label: '联系我们',
    key: 'about-p10',
  },
]

function JSAPEAboutSide ({ selectKey }) {

  const navigate = useNavigate()
  const onClick = ({ key }) => {

    switch (key) {
      case 'about-p1':
      case 'about-p2':
      case 'about-p3':
      case 'about-p4':
      case 'about-p5':
      case 'about-p6':
      case 'about-p7':
      case 'about-p8':
      case 'about-p9':
      case 'about-p10':
        navigate(`/aboutus/${key}`)
        break
      case 'members':
        navigate('/members/search?q=')
        break
      default:
        navigate('/notfound')
        break

    }
  }
  return <>

    <Menu mode="vertical" onClick={onClick} items={menu_item} defaultSelectedKeys={selectKey} />

  </>
}

export default JSAPEAboutSide