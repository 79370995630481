import React from 'react'
import { observer } from 'mobx-react-lite'
import { Layout, Row, Col, Form, Input, Button, Breadcrumb, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { useStore } from '@/store'
import { useSearchParams, useNavigate } from 'react-router-dom'


import './index.scss'

const { Content } = Layout


function Login () {
  const [searchParams] = useSearchParams()
  const ref = searchParams.get('ref') || '/members/my/'

  const navigate = useNavigate()

  const { loginStore } = useStore()
  if (loginStore.isLogin) {
    navigate(ref)
  }

  var onLogin = async (values) => {
    //console.log(values)
    const res = await loginStore.login({ loginname: values.loginname, password: values.password })
    if (res) {
      message.success('登陆成功')
      navigate(ref)
    } else {
      message.error('用户名或密码错误')
    }
  }



  // const navigate = useNavigate()
  // const toDetail = (id) => {
  //   navigate(`?id=${id}`)
  // }
  return (
    <>
      <Content>
        <div className="site-layout-background">
          <Breadcrumb separator="">
            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
            <Breadcrumb.Separator>:</Breadcrumb.Separator>
            <Breadcrumb.Item href="/">
              首页
            </Breadcrumb.Item>
            <Breadcrumb.Separator />

            <Breadcrumb.Item>登陆</Breadcrumb.Item>
          </Breadcrumb>

          <Row>
            <Col span={12} offset={6}>
              <Form name="login_form" size="large" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onLogin}>
                <Form.Item label="登陆名称" name="loginname" rules={[{
                  required: true,
                  message: '请输入登陆名称'
                }]}>
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                </Form.Item>
                <Form.Item label="登陆密码" name="password" rules={[
                  {
                    required: true,
                    message: '请输入登陆密码',
                  },
                ]}>
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    登陆
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>

        </div>
      </Content>
    </>
  )
}

export default observer(Login)