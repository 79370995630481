import { useParams, Outlet } from 'react-router-dom'
import JSAPE_Header from '@/components/JSAPE_Header'
import JSAPE_Footer from '@/components/JSAPE_Footer'
import { Layout } from 'antd'


function LayoutNotSider () {
  const params = useParams()
  const channel = params.channel

  return (
    <>
      <Layout>
        <JSAPE_Header selectKey={[channel]} />
        <Outlet />
        <JSAPE_Footer />
      </Layout>
    </>
  )
}

export default LayoutNotSider