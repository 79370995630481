//https://docs.cloudbase.net/cms/usage/restful
import { makeAutoObservable } from "mobx"
import { http } from '@/utils'

class ArticlesStore {

  result = {}

  constructor() {
    makeAutoObservable(this)
  }

  search = async ({ limit = 20, skip = 0, channel = null, query, sort }) => {
    const uri = '/articles/search'
    return await http.get(uri, {
      params: {
        'limit': limit, 'offset': skip, 'channel': channel, 'keywords': query
      }
    }).then((res) => {
      this.result = res

    }).catch((err) => {
      console.err(err)
    })
  }

  list = async ({ channel, limit = 20, offset = 0, sort, fields }) => {
    const uri = '/articles'
    return await http.get(uri, {
      params: {
        channel, limit, fields, sort, offset,
      }
    }).then((res) => {
      this.result = res
      return res
    }).catch((err) => {
      console.err(err)
    })
  }

  getById = async ({ channel, id }) => {
    const uri = '/articles/get'
    return await http.get(uri, {
      params: {
        'id': id
      }
    }).then((res) => {
      this.result = res

    }).catch((err) => {
      console.err(err)
    })
  }

  getOnePage = async ({ collection, make }) => {
    //console.log('make', make)
    const query = { "$and": [{ 'make': { '$eq': make } }] }

    const uri = `https://api.jsape.com/api/v1.0/${collection}/find?limit=${1}&skip=${0}`
    const res = await http.post(uri, {
      query: {
        ...query
      }
    })
    this.result = res

  }//getOnePage

}

export default ArticlesStore