import { useEffect, useState } from 'react'
import { useStore } from '@/store'
import { Pagination, Divider, List, Breadcrumb, Typography } from 'antd'
import { useSearchParams } from 'react-router-dom'

const { Link } = Typography

function Articles_Search () {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const channel = searchParams.get('channel')

  const [data, setData] = useState({
    list: [],
    total: 0,
    limit: 20,
    currentPage: 1
  })

  const [params, setParams] = useState({
    limit: 20,
    skip: 0,
    //total: 0,
    channel: channel,
    query: query,
    //sort: null,
    //fields: null
  })

  const { articlesStore } = useStore()

  useEffect(() => {
    setParams({ ...params, channel: channel, query: query })
  }, [channel, query])

  useEffect(() => {
    const getList = async () => {
      await articlesStore.search(params)
      const res = articlesStore.result

      setData({
        list: res.data,
        total: res.total,
        limit: res.limit,
        currentPage: (res.offset / res.limit) + 1
      })
    }
    getList()

  }, [params])

  //const navigate = useNavigate()

  const onChange = (page, pageSize) => {
    setParams({
      ...params,
      skip: pageSize * (page - 1),
      limit: pageSize,
    })
  }

  return (
    <>
      <Breadcrumb separator="">
        <Breadcrumb.Item>当前位置</Breadcrumb.Item>
        <Breadcrumb.Separator>:</Breadcrumb.Separator>
        <Breadcrumb.Item href="/">
          首页
        </Breadcrumb.Item>
        <Breadcrumb.Separator />

        <Breadcrumb.Item>搜索到符合 {query} 共 {data.total} 条</Breadcrumb.Item>
      </Breadcrumb>

      <Divider />
      <List size="small" dataSource={data.list} renderItem={(item) => (
        <List.Item>
          {
            item.url ? <Link href={item.url} target='_blank'>{item.title}</Link>
              : <Link href={'/articles/' + item.channel + '/' + item._id}>{item.title}</Link>
          }
        </List.Item>
      )} />
      <p />
      <Pagination hideOnSinglePage showSizeChanger={false} current={data.currentPage} pageSize={data.limit} total={data.total} onChange={onChange} />
    </>
  )
}

export default (Articles_Search)