import React from 'react'
import LoginStore from './login.store'
import MemberStore from './member.store'
import ArticlesStore from './articles.store'
import CrouselStore from './carousel.store'

class RootStore {
  constructor() {
    this.loginStore = new LoginStore()
    this.memberStore = new MemberStore()
    this.articlesStore = new ArticlesStore()
    this.crouselStore = new CrouselStore()
  }
}


const context = React.createContext(new RootStore())

const useStore = () => React.useContext(context)

export { useStore }