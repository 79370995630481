import { useEffect, useState } from 'react'
import { useStore } from '@/store'

import { List, Pagination, Divider, Typography } from 'antd'

import JSAPEBreadcrumb from '@/components/JSAPE_Breadcrumb'

import { useParams, useSearchParams, useNavigate } from 'react-router-dom'


const { Link } = Typography

function Articles () {
  const query_params = useParams()
  const channel = query_params.channel

  const [searchParams] = useSearchParams()
  const limit = searchParams.get('limit') || 20
  const offset = searchParams.get('offset') || 0


  const [data, setData] = useState({
    list: [],
    total: 0,
    limit: 20,
    currentPage: 1
  })

  const [params, setParams] = useState({
    limit: 20,
    offset: 0,
    channel: channel,
  })

  useEffect(() => {
    setParams({ ...params, channel, limit, offset })
  }, [channel, limit, offset])

  const { articlesStore } = useStore()

  useEffect(() => {

    const getList = async () => {

      await articlesStore.list(params)
      const res = articlesStore.result
      setData({
        list: res.data,
        total: res.total,
        limit: res.limit,
        currentPage: (res.offset / res.limit) + 1
      })
    }

    getList()

  }, [params])

  const navigate = useNavigate()

  const onChange = (page, pageSize) => {
    //console.log(page)
    //const isChangedPageSize = (pageSize != params.limit)
    var offset = pageSize * (page - 1)
    navigate(`/articles/${channel}?limit=${pageSize}&offset=${offset}`)
    // setParams({
    //   ...params,
    //   offset: isChangedPageSize ? 0 : pageSize * (page - 1),
    //   limit: pageSize,
    // })

    // if (isChangedPageSize) {
    //   setData({ ...data, currentPage: 1 })
    // }
  }

  return (
    <>
      <JSAPEBreadcrumb channel={channel} />

      <Divider />
      <List size="small" dataSource={data.list} renderItem={(item) => (
        <List.Item>
          {
            item.url ? <Link href={item.url} target='_blank'>{item.title}</Link>
              : <Link href={'/articles/' + item.channel + '/' + item._id}>{item.title}</Link>
          }
        </List.Item>
      )} />
      <p />
      <Pagination hideOnSinglePage showSizeChanger={false} current={data.currentPage} pageSize={data.limit} total={data.total} onChange={onChange} />
    </>
  )
}

export default (Articles)