import { useEffect, useState } from 'react'
import { useStore } from '@/store'

import { Pagination, Divider, List, Breadcrumb, Typography } from 'antd'

import { useSearchParams } from 'react-router-dom'

const { Link } = Typography

function Member () {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')

  const [data, setData] = useState({
    list: [],
    total: 0,
    limit: 20,
    currentPage: 1
  })

  const [params, setParams] = useState({
    limit: 20,
    skip: 0,
    query: query,
  })


  useEffect(() => {
    setParams({ ...params, query: query })
  }, [query])

  const { memberStore } = useStore()

  useEffect(() => {
    const getList = async () => {
      const res = await memberStore.search(params)

      setData({
        list: res.data,
        total: res.total,
        limit: res.limit,
        currentPage: (res.offset / res.limit) + 1
      })
    }
    getList()

  }, [params, memberStore])

  const onChange = (page, pageSize) => {
    setParams({
      ...params,
      skip: pageSize * (page - 1),
      limit: pageSize,
    })

  }

  return (
    <>
      <Breadcrumb separator="">
        <Breadcrumb.Item>当前位置</Breadcrumb.Item>
        <Breadcrumb.Separator>:</Breadcrumb.Separator>
        <Breadcrumb.Item href="/">
          首页
        </Breadcrumb.Item>
        <Breadcrumb.Separator />

        <Breadcrumb.Item>搜索到会员信息符合 {query} 共 {data.total} 条</Breadcrumb.Item>
      </Breadcrumb>

      <Divider />
      <List size="small" dataSource={data.list} renderItem={(item) => (
        <List.Item>
          <Link href={
            '/members/' + item._id
          }>{item.CompanyName}</Link>
        </List.Item>
      )} />
      <p />
      <Pagination hideOnSinglePage showSizeChanger={false} current={data.currentPage} pageSize={data.limit} total={data.total} onChange={onChange} />

    </>

  )

}

export default (Member)